import "./App.css";
import "./firebase/config";

import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import { ParallaxProvider } from "react-scroll-parallax";
import { FaWhatsapp } from "react-icons/fa";
import { Suspense, lazy } from "react";

const NavBar = lazy(() => import("./components/navbar"));
const Banner = lazy(() => import("./components/banner"));
const About = lazy(() => import("./components/about"));
const Zodiac = lazy(() => import("./components/zodiac"));
const Services = lazy(() => import("./components/services"));
const Testimonials = lazy(() => import("./components/testimonials"));
const Panchang = lazy(() => import("./components/panchang"));
const MastroFooter = lazy(() => import("./components/mastroFooter"));

function App() {
  return (
    <Suspense fallback={<div>Loading Best Astrology service...</div>}>
      <ParallaxProvider>
        <div className="App">
          <Layout style={{ backgroundColor: "#161616" }}>
            <NavBar />
            <Content style={{ marginTop: 20 }}>
              <Banner />
              <About />
              <Zodiac />
              <Services />
              <Testimonials />
              <Panchang />
            </Content>
            <MastroFooter />
          </Layout>
        </div>
      </ParallaxProvider>
      <a
        href="https://api.whatsapp.com/send?phone=60183553290&text=नमस्कार%2C%20मैं%20Mastroify%20की%20सेवाएं%20लेना%20चाहता%20हूँ।"
        target="_blank"
        rel="noopener noreferrer"
        className="floating"
        aria-label="WhatsApp-Sticky"
      >
        <FaWhatsapp />
      </a>
    </Suspense>
  );
}

export default App;
